import $ from "jquery";

export function init() {

	if($('html').hasClass('greenpest')) {
		$('[class*="-primary-100"]').each(function (index) {
			if ($(this).hasClass('bg-primary-100')) {
				$(this).removeClass('bg-primary-100').addClass('bg-greenpest-100');
			}
			if ($(this).hasClass('hover:bg-primary-100')) {
				$(this).removeClass('hover:bg-primary-100').addClass('hover:bg-greenpest-100');
			}

			if ($(this).hasClass('text-primary-100')) {
				$(this).removeClass('text-primary-100').addClass('text-greenpest-100');
			}
			if ($(this).hasClass('hover:text-primary-100')) {
				$(this).removeClass('hover:text-primary-100').addClass('hover:text-greenpest-100');
			}

			if ($(this).hasClass('border-primary-100')) {
				$(this).removeClass('border-primary-100').addClass('border-greenpest-100');
			}
			if ($(this).hasClass('hover:border-primary-100')) {
				$(this).removeClass('hover:border-primary-100').addClass('hover:border-greenpest-100');
			}
			if ($(this).hasClass('focus:border-primary-100')) {
				$(this).removeClass('focus:border-primary-100').addClass('focus:border-greenpest-100');
			}
		});

		$('[class*="-primary-200"]').each(function (index) {
			if ($(this).hasClass('bg-primary-200')) {
				$(this).removeClass('bg-primary-200').addClass('bg-greenpest-200');
			}
			if ($(this).hasClass('hover:bg-primary-200')) {
				$(this).removeClass('hover:bg-primary-200').addClass('hover:bg-greenpest-200');
			}

			if ($(this).hasClass('text-primary-200')) {
				$(this).removeClass('text-primary-200').addClass('text-greenpest-200');
			}
			if ($(this).hasClass('hover:text-primary-200')) {
				$(this).removeClass('hover:text-primary-200').addClass('hover:text-greenpest-200');
			}

			if ($(this).hasClass('border-primary-200')) {
				$(this).removeClass('border-primary-200').addClass('border-greenpest-200');
			}
			if ($(this).hasClass('hover:border-primary-200')) {
				$(this).removeClass('hover:border-primary-200').addClass('hover:border-greenpest-200');
			}
			if ($(this).hasClass('focus:border-primary-200')) {
				$(this).removeClass('focus:border-primary-200').addClass('focus:border-greenpest-200');
			}
		});

		$('.shadow-text-highlight-primary').each(function (index) {
			$(this).removeClass('shadow-text-highlight-primary').addClass('shadow-text-highlight-greenpest');
		});
		
	}
}