import $ from "jquery";

export function init() {
	$('.three-rivers-map g').hover(function (e) {
		var id = $(this).attr('id');
		$('[data-map-name="'+id+'"').addClass('pl-4');
	},function(e) {
		$('.map-names a').removeClass('pl-4');
	});

	$('.three-rivers-map g').click(function (e) {
		console.log('click');
		$('.three-rivers-map g').removeClass('active');
		$(this).addClass('active');
		var id = $(this).attr('id');
		$('[data-map-name="' + id + '"')[0].click();
	});
}